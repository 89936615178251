$select_claim_cell_width: 55px;
$case_no_cell_width: 140px;
$claim_actions_cell_width: 110px;
$sticky_columns_z_index: 999;

.claims-table-container {
  width: 100%;
  overflow-x: scroll;
  scroll-padding-inline-start: $select_claim_cell_width + $case_no_cell_width;
  scroll-padding-inline-end: $claim_actions_cell_width;

  .claims-table {
    table-layout: fixed;
  }

  .claims-table > thead {
    position: sticky;
    top: 0;
    z-index: 1000;
  }

  .claims-table > thead > tr,
  .claims-table > tbody > tr {
    > th,
    > td {
      border-bottom-width: 0;

      > .inner-cell {
        border-bottom-width: 1px;
        border-bottom-color: rgb(222, 230, 237);
        border-bottom-style: solid;
        border-right-width: 1px;
        border-right-color: rgb(222, 230, 237);
        border-right-style: solid;
      }

      &.select_claim {
        position: sticky;
        z-index: $sticky_columns_z_index;
        left: 0;
        width: $select_claim_cell_width;
        > label {
          cursor: pointer;
          > input {
            cursor: pointer;
          }
        }
      }

      &.case_no {
        position: sticky;
        z-index: $sticky_columns_z_index;
        left: $select_claim_cell_width;
      }

      &.claim_actions {
        position: sticky;
        z-index: $sticky_columns_z_index;
        right: 0;
        width: $claim_actions_cell_width;
      }

      // `md` applies to small devices (landscape phones, less than 768px)
      @media (max-width: 767.98px) {
        &.case_no {
          position: static;
        }
      }
      // `xl` applies to large devices (desktops, less than 1200px)
      @media (max-width: 1199.98px) {
        &.claim_actions {
          position: static;
        }
      }
    }
  }

  .claims-table > thead > tr > th {
    padding: 0;

    > .inner-cell {
      padding: 0.25rem 0.5rem;
      border-top-width: 1px;
      border-top-color: rgb(222, 230, 237);
      border-top-style: solid;
    }
  }

  .claims-table > tbody > tr {
    > td {
      padding: 0;

      > .inner-cell {
        padding: 0.25rem 0.5rem;

        .inner-cell-value {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .edit-icon {
          opacity: 0;
        }
        &:hover {
          .edit-icon {
            opacity: 1;
          }
        }
      }

      // Increment z-index on focus, set outline color and offset
      // Since sticky columns have a greater z-index increment more these to avoid non sticky focus cells go over sticky ones
      &:focus {
        &.select_claim,
        &.case_no {
          z-index: 1000;
        }

        z-index: 998;
        outline: 1px solid rgb(63, 128, 234);
        outline-offset: -1px;
      }
    }
  }
}
