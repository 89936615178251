.file-drop-area {
  border: 5px dashed rgb(222, 230, 237);
  background-color: #FFF;
  transition: border-color .3s, background-color .3s;

  &.over {
    background-color: rgba(63, 128, 234, 0.1);
    border-color: rgb(63, 128, 234);
  }
  &.invalidFiletypeError {
    background-color: rgb(217, 83, 79, 0.1);
    border-color: rgb(217, 83, 79);
  }
}
