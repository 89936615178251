.upload-form {
  min-height: 400px;

  .card-body {
    padding: 0;
    display: flex;
    flex-direction: row;

    aside {
      width: 200px;
      display: flex;
      flex-direction: column;

      .integrations {
        flex-direction: column;
        > .nav-item {
          .nav-link {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            border-radius: 0;
            color: rgb(108, 117, 125);
            border-left: 5px solid transparent;
            padding: 1rem;

            &.active {
              color: rgb(63, 128, 234);
              background-color: rgba(63, 128, 234, 0.1);
              border-left-color: rgb(63, 128, 234);
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}
