.custom-checkbox {
  position: relative;
  .form-check-input {
    pointer-events: none;
  }

  .hover-checkbox {
    display: none;
  }

  &:not(.checked):hover {
    .actual-checkbox {
      display: none;
    }
    .hover-checkbox {
      display: block;
    }
  }
}
