.submit-progress-wrapper {
  .please-wait-title {
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  }
  .progress-title {
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  }
  .done-title {
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
    opacity: 0;
    pointer-events: none;
  }
  .progress-actions {
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
    opacity: 0;
    transform: translateY(-100px);
    pointer-events: none;
  }

  .submit-progress-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1rem;
    width: 100%;

    .submit-progress-bar {
      position: relative;
      width: 100px;
      height: 3px;
      background-color: rgba(78, 69, 68, 0.3);
      overflow: hidden;
      flex: 0 0 auto;
      transition: opacity 0.5s;

      > div {
        position: absolute;
        left: -10px;
        width: 10px;
        height: 3px;
        background-color: #ff6a14;
        animation: progress-animation 2s ease-in-out infinite;
        &:nth-child(1) {
          animation-delay: 0;
        }
        &:nth-child(2) {
          animation-delay: 0.5s;
        }
        &:nth-child(3) {
          animation-delay: 1s;
        }
      }
    }

    svg {
      flex: 0 0 auto;
      &.bkalerts-icon {
        transition: opacity 0.5s ease-in;
      }
      &.poc-icon {
        transition: transform 0.6s ease-in-out, opacity 0.5s ease-in;
      }
      &.court-icon {
        transition: transform 0.6s ease-in-out;
        #CourtCircle > circle {
          transition: stroke 0.6s ease-in-out, stroke-opacity 0.6s ease-in-out;
        }
        .success-circle {
          opacity: 0;
          transition: 0.6s opacity;

          circle {
            transition: 0.6s stroke-dashoffset;
            transition-delay: 0.4s;
            // axis compensation
            translate: 85px;
            transform: rotate(-90deg);
            transform-origin: center;
          }
        }
      }
    }
  }

  &.finished {
    .please-wait-title {
      transform: translateY(-1.65rem);
      opacity: 0;
      pointer-events: none;
    }
    .progress-title {
      opacity: 0;
      transform: translateY(1.65rem);
      pointer-events: none;
    }
    .done-title {
      opacity: 1;
      transform: translateY(-1.65rem);
      pointer-events: all;
    }
    .submit-progress-bar {
      opacity: 0;
    }
    svg.bkalerts-icon {
      opacity: 0;
    }
    svg.poc-icon {
      transform: translateX(calc(120px + 100px + 2rem));
      opacity: 0;
    }
    svg.court-icon {
      transform: translateX(calc(-100px - 120px - 2rem)) scale(1.3);
      #CourtCircle > circle {
        stroke-opacity: 1;
      }
    }
    .progress-actions {
      opacity: 1;
      transform: translateY(0);
      pointer-events: all;
    }

    &.succeeded {
      svg.court-icon {
        #CourtCircle > circle {
          stroke: var(--bs-success);
        }
        .success-circle {
          opacity: 1;
          > circle {
            stroke: var(--bs-success);
            stroke-dashoffset: 0;
          }
        }
      }
    }
    &.failed {
      svg.court-icon {
        #CourtCircle > circle {
          stroke: var(--bs-danger);
        }
      }
    }
  }
}

@keyframes progress-animation {
  from {
    left: -10px;
  }
  to {
    left: 110px;
  }
}
