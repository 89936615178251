.tracked-debtors {
  padding-left: 0 !important;
  padding-right: 0 !important;

  .nav.nav-tabs.tracked-debtors-tabs {
    > .nav-item {
      .nav-link {
        border-top: 5px solid transparent;
        border-right: none;
        border-bottom: none;
        border-left: none;
        border-radius: 0;

        &.active {
          --bs-nav-tabs-link-active-bg: #fff !important;
          border-top-color: rgb(63, 128, 234);
        }
      }
    }
  }

  .card.tracked-debtors-wrapper {
    border-radius: 0 !important;
  }

  .card {
    table.tracked-debtors-table {
      thead {
        position: sticky;
        top: 0;

        tr th {
          box-shadow: inset 0 -1px 0 var(--bs-border-color);
          border-bottom: 0;
          white-space: nowrap;
          vertical-align: middle;
          &.selectall {
            padding-left: calc(1.25rem - 0.7rem);
            // min content width trick
            width: 1%;
            white-space: nowrap;
          }
        }
      }

      // `md` applies to small devices (landscape phones, less than 768px)
      @media (max-width: 767.98px) {
        > thead > tr.table-header-row {
          display: grid;
          width: 100%;
          grid-template-columns: 85px 2fr 2fr;
          > th {
            display: flex;
            align-items: center;
            &.name,
            &.address,
            &.phone,
            &.createdat {
              display: none;
            }
          }
        }
        > tbody > tr {
          display: grid;
          width: 100%;
          grid-template-columns: 85px 2fr 2fr;
          > td {
            display: flex;
            align-items: center;
            &.name,
            &.address,
            &.phone,
            &.createdat {
              display: none;
            }
          }
        }
      }
    }

    .table-footer {
      position: sticky;
      bottom: 0;
      background-color: #fff;
    }
  }
}
