.app-sidebar {
  display: flex;
  flex-direction: column;
  min-width: 0 !important;
  max-width: none !important;

  &.sidebar-collapsed {
    .sidebar-link {
      justify-content: center;
    }
  }
}

.app-sidebar,
.app-offcanvas-sidebar {
  .sidebar-category-label {
    text-transform: uppercase;
    color: rgb(233, 236, 239);
    margin-top: 1rem;
    margin-bottom: 0.25rem;
    margin-left: 16px;
  }

  .sidebar-link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 0;
    padding-right: 0;

    &.active {
      background-color: var(--bs-nav-pills-link-active-bg);
    }

    > svg {
      width: 54px;
      margin-right: 0;
    }
    > span {
      white-space: nowrap;
    }
  }
}
