.grid-table {
  .grid-table-header {
    display: grid;
    grid-template-columns: var(--grid-table-template-columns);
    border-bottom: 1px solid var(--bs-gray-400);
    background-color: var(--bs-border-color);
    color: var(--bs-gray-dark);

    &.sticky {
      position: sticky;
      top: var(--grid-table-header-sticky-top);
    }

    > .grid-table-header-cell {
      font-weight: 500;
      padding: 1rem 0.5rem;
    }
  }

  .grid-table-body-row {
    display: grid;
    grid-template-columns: var(--grid-table-template-columns);
    border-bottom: 1px solid var(--bs-gray-300);

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background-color: var(--bs-gray-100);
    }

    > .grid-table-body-cell {
      padding: 1rem 0.5rem;
    }
  }
}
