.svgWrapper {
  display: inline-block;
}

.circle {
  fill: none;
  stroke-width: 3;
}

.bg-circle {
  stroke: #e0e0e0;
}

.fill-circle {
  stroke-dasharray: 0 100;
  stroke-dashoffset: 0;
  transition: stroke-dasharray 1s ease-out, stroke-dashoffset 1s ease-out;
}
