.app-sidebar-toggle {
  width: 54px;

  .hamburger-button {
    width: 38px;
    height: 38px;
    margin-inline: auto;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(233, 236, 239, 0.1);
    transition: 0.3s background-color;
    &:hover {
      background-color: rgba(233, 236, 239, 0.2);
    }
  }
}
