table.search-cases {
  thead > tr > th,
  tbody > tr > td {
    &.title {
      grid-area: title;
    }
    &.case_no {
      grid-area: case_no;
    }
    &.court {
      grid-area: court;
    }
    &.chapter {
      grid-area: chapter;
    }
    &.date_filed {
      grid-area: date_filed;
    }
    &.date_closed {
      grid-area: date_closed;
    }
    &.disposition {
      grid-area: disposition;
    }
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  table.search-cases {
    thead {
      position: sticky;
      top: 0;
      background-color: #fff;
      z-index: 3;
    }

    thead > tr {
      display: grid;
      grid-template-areas: "case_no chapter date_filed";
      grid-template-columns: 2fr 1fr 110px;
    }

    tbody > tr {
      display: grid;
      grid-template-areas:
        "case_no chapter date_filed"
        "title title title"
        "court court date_closed"
        "disposition disposition disposition";
      grid-template-columns: 2fr 1fr 110px;
      border-bottom: 1px solid #dee2e6;

      &:last-child {
        border-bottom: none;
      }

      &.no-records-found {
        > td {
          grid-area: no_records_found;
        }

        grid-template-areas: "no_records_found no_records_found no_records_found";
      }

      &.show_more {
        > td {
          grid-area: show_more;
          &:last-child {
            padding-right: 0.3rem;
          }
        }

        grid-template-areas: "show_more show_more show_more";
      }
    }

    tbody > tr > td {
      border-top: none;

      &.disposition:empty {
        display: none;
      }
    }

    tbody > tr > td,
    thead > tr > th {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    thead > tr > th {
      &.title,
      &.court,
      &.date_closed,
      &.disposition {
        display: none;
      }
    }
  }

  .card > .table.search-cases > thead > tr > th:first-child,
  .card > .table.search-cases > tbody > tr > td:first-child {
    padding-left: 0.3rem;
  }
}
