.loading-cell {
  display: inline-block;
  width: 100%;
  height: 16px;
  background-color: #eee;
  animation: blinkingBackground 2s infinite;
  animation-direction: alternate;
}

@keyframes blinkingBackground {
  0% {
    background-color: #e1e1e1;
  }
  25% {
    background-color: #d7d7d7;
  }
  50% {
    background-color: #cccccc;
  }
  75% {
    background-color: #c2c2c2;
  }
  100% {
    background-color: #b7b7b7;
  }
}
