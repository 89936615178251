.search-input-form {
  .search-wrapper {
    flex-wrap: nowrap;

    .search-input-wrapper {
      flex-grow: 1;
      position: relative;
      input {
        padding-right: 32px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      button {
        padding: 0;
        width: 32px;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}
