.tracked-cases-table {
  > thead {
    position: sticky;
    top: 0;
  }

  > thead > tr > th {
    vertical-align: middle;
    box-shadow: inset 0 -1px 0 var(--bs-border-color);
    border-bottom: 0;
    white-space: nowrap;

    &.select_all {
      padding-left: 0.55rem !important;
    }
  }
}
