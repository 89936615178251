$epoc-header-height: 60px;
$epoc-errors-header-height: 60px;

.epoc {
  #part-1,
  #part-2,
  #part-3,
  input,
  textarea,
  select,
  #securities-container,
  #priorities-container,
  #creditors_attorney-container,
  #payment_address-container {
    // ePOC header height + app navbar height
    scroll-margin-top: calc($epoc-header-height + $epoc-errors-header-height + 1rem);
    // safari only
    scroll-snap-margin-top: calc($epoc-header-height + $epoc-errors-header-height + 1rem);
  }

  .steps-wrapper {
    position: sticky;
    top: $epoc-header-height;
    .steps {
      .step {
        .step-circle {
          display: flex;
          width: 32px;
          height: 32px;
          border: 2px solid var(--bs-gray-400);
          border-radius: 50%;
          padding: 2px;
          transition: border-color 0.2s;
          cursor: default;

          .step-number {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            background-color: var(--bs-gray-400);
            border-radius: 50%;
            color: #fff;
            transition: background-color 0.2s;
          }
        }

        &.current {
          .step-circle {
            border-color: var(--bs-primary);
            .step-number {
              background-color: var(--bs-primary);
            }
          }
        }

        &.done {
          .step-circle {
            border-color: var(--bs-success);
            .step-number {
              background-color: var(--bs-success);
            }
          }
        }

        .separator {
          width: 2px;
          min-height: 30px;
          align-self: center;
          flex-grow: 1;
          background-color: var(--bs-gray-400);
        }
        &.current {
          .separator {
            background-color: var(--bs-primary);
          }
        }
        &.done {
          .separator {
            background-color: var(--bs-success);
          }
        }
      }
    }
  }

  label:not(.btn) {
    font-weight: 500;
    color: #495057;
  }

  .form-control,
  .form-select {
    width: auto;
  }

  .form-check {
    .form-check-input {
      cursor: pointer;
    }
    .form-check-label {
      cursor: pointer;
    }
  }

  .errors-header {
    position: sticky;
    top: $epoc-header-height;
    height: $epoc-errors-header-height;
    display: flex;
    align-items: center;
    z-index: 1000;
  }
}
