.dockets-table.table-hover tbody tr.no-hover:hover {
  background-color: inherit;
}

.docket-documents {
  width: auto;
}

.docket-documents tbody tr,
.docket-documents tbody tr:hover,
.docket-documents tbody td,
.docket-documents thead tr,
.docket-documents thead tr:hover,
.docket-documents thead th {
  background-color: transparent;
  border: none;
}

.docket-documents thead tr th:first-child,
.docket-documents tbody tr td:first-child {
  padding-left: 0 !important;
}

table.dockets-table {
  & > thead > tr > th,
  & > tbody > tr > td {
    &.date_filed {
      grid-area: date_filed;
    }
    &.docket_seq {
      grid-area: docket_seq;
    }
    &.docket_text {
      grid-area: docket_text;
    }
    &.documents {
      grid-area: documents;
    }
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  table.dockets-table {
    & > thead {
      position: sticky;
      top: 0;
      background-color: #fff;
      z-index: 3;
    }

    & > thead > tr {
      display: grid;
      grid-template-areas: "date_filed docket_seq documents";
      grid-template-columns: 1fr 1fr 1fr;
    }

    & > tbody > tr {
      display: grid;
      grid-template-areas:
        "date_filed docket_seq documents"
        "docket_text docket_text docket_text";
      grid-template-columns: 1fr 1fr 1fr;
      border-bottom: 1px solid #dee2e6;

      &:last-child {
        border-bottom: none;
      }

      &.no-records-found {
        > td {
          grid-area: no_records_found;
        }

        grid-template-areas: "no_records_found no_records_found no_records_found";
      }
    }

    & > tbody > tr > td {
      border-top: none;
      &.documents {
        align-items: flex-end;
      }
    }

    & > tbody > tr > td,
    & > thead > tr > th {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    & > thead > tr > th {
      &.docket_text {
        display: none;
      }
    }
  }

  .card > .table.dockets-table > thead > tr > th:first-child,
  .card > .table.dockets-table > tbody > tr > td:first-child {
    padding-left: 0.3rem;
  }
  .card > .table.dockets-table > thead > tr > th:last-child,
  .card > .table.dockets-table > tbody > tr > td:last-child {
    padding-right: 0.3rem;
  }
}
