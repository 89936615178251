.nav.nav-tabs.import-tabs {
  > .nav-item {
    .nav-link {
      border-top: 5px solid transparent;
      border-right: none;
      border-bottom: none;
      border-left: none;
      border-radius: 0;

      &.active {
        --bs-nav-tabs-link-active-bg: #fff !important;
        border-top-color: rgb(63, 128, 234);
      }
    }
  }
}

.card.import-card {
  border-radius: 0 !important;
}
