.app-container {
  display: grid;
  height: 100%;
  grid-template-areas:
    "navbar navbar"
    "sidebar main";
  grid-template-columns: 200px 1fr;
  grid-template-rows: 58px 1fr;

  > .app-navbar {
    grid-area: navbar;
  }

  > .app-sidebar {
    grid-area: sidebar;
  }

  > .app-main {
    grid-area: main;
    overflow-y: auto;
  }

  &.collapsed {
    grid-template-columns: 54px 1fr;
  }

  @media screen and (max-width: 991.98px) {
    > .app-sidebar {
      display: none;
    }

    grid-template-areas:
      "navbar"
      "main";
    grid-template-columns: 1fr;

    &.collapsed {
      grid-template-columns: 1fr;
    }
  }
}
