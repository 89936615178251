.app-navbar {
  display: grid;
  grid-template-columns: 200px 1fr auto auto;

  @media screen and (max-width: 991.98px) {
    grid-template-columns: auto 1fr auto auto;
    gap: 1rem;
  }

  form.search-cases-form {
    label {
      color: rgba(255, 255, 255, 0.75);
    }
    input.form-control {
      background-color: #354052;
      color: rgba(255, 255, 255, 0.75);
      border-color: rgba(255, 255, 255, 0.25);

      &:focus {
        box-shadow: none;
        border-color: rgba(255, 255, 255, 0.75);
      }
    }

    button.btn {
      &:focus {
        box-shadow: none;
      }
    }
  }
}
