.min-vw-50 {
  min-width: 50vw !important;
}
.min-vh-50 {
  min-height: 50vh !important;
}

.vw-50 {
  width: 50vw !important;
}
.vh-50 {
  height: 50vh !important;
}
