.sort-icons {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sort-icon {
  cursor: pointer;
  &--disabled {
    opacity: 0.5;
  }
}
