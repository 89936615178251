.table {
  thead,
  tbody,
  tfoot,
  tr,
  td,
  th {
    border-color: $table-border-color;
  }
}

.card {
  > .dataTables_wrapper .table.dataTable,
  > .table,
  > .table-responsive .table,
  > .table-responsive-sm .table,
  > .table-responsive-md .table,
  > .table-responsive-lg .table,
  > .table-responsive-xl .table {
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
    margin-bottom: 0;

    td:first-child,
    th:first-child {
      border-left: 0;
      padding-left: $spacer * 1.25;
    }

    td:last-child,
    th:last-child {
      border-right: 0;
      padding-right: $spacer * 1.25;
    }

    tr:first-child td,
    tr:first-child th {
      border-top: 0;
    }

    tr:last-child td {
      border-bottom: 0;
    }
  }

  .card-header + .table {
    border-top: 0;
  }
}

.table-action {
  a {
    color: $gray-600;

    &:hover {
      color: $gray-900;
    }
  }

  .feather {
    width: 18px;
    height: 18px;
  }
}

.table > tbody > tr > td {
  vertical-align: middle;
}

.card > .dataTables_wrapper {
  .table.dataTable {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .dataTables_info {
    padding: 1rem 1.25rem;
  }

  .dataTables_paginate {
    padding: 0.6rem 1.25rem;
  }
}

.dt-bootstrap4 {
  width: calc(100% - 2px);
}

table.dataTable tbody > tr.selected,
table.dataTable tbody > tr > .selected {
  --bs-table-accent-bg: $primary;
}

td,
th {
  &.min-content-width {
    width: 1%;
    white-space: nowrap;
  }
}
