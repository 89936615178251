.paste-csv-textarea {
  display: flex;
  flex-direction: row;
  .line-numbers {
    width: 3rem;
    padding: 0.25rem 0.25rem 0.25rem 0.7rem;
    text-align: right;
    margin: 0;
    list-style: none;
    font-size: 0.825rem;
    line-height: 1.625;
  }
  textarea.form-control {
    border: none;
    resize: none;
    &:focus {
      border: none;
      box-shadow: none;
    }
  }
}
