.your-claims-table {
  > thead > tr > th {
    vertical-align: middle;
    box-shadow: inset 0 -1px 0 var(--bs-border-color);
    border-bottom: 0;
    white-space: nowrap;
  }

  // `md` applies to small devices (landscape phones, less than 768px)
  @media (max-width: 767.98px) {
    > thead > tr > th,
    > tbody > tr > td {
      &.court,
      &.updated_at,
      &.status {
        display: none;
      }
    }
  }
}
