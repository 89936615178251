.matches-page {
  .matches-table-container {
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;

    .matches-table {
      table-layout: fixed;

      > thead > tr > th,
      > tbody > tr > td {
        padding: 0.25rem 0.5rem;

        > .inner-cell {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      > thead > tr > th {
        box-shadow: inset 0 -1px 0 var(--bs-border-color), inset -1px 0 0 var(--bs-border-color);
        border-bottom: 0;

        &.source {
          position: sticky;
          left: 0;
        }

        &.phone {
          box-shadow: inset 0 -1px 0 var(--bs-border-color);
        }

        &.match {
          position: sticky;
          right: 0;
          box-shadow: inset 0 -1px 0 var(--bs-border-color), inset 1px 0 0 var(--bs-border-color);
        }
      }

      > tbody > tr > td {
        box-shadow: inset -1px 0 0 var(--bs-border-color);

        &.customer_source,
        &.court_source {
          position: sticky;
          left: 0;
        }

        &.customer_phone,
        &.court_phone {
          box-shadow: none;
        }

        &.match {
          position: sticky;
          right: 0;
          box-shadow: inset 1px 0 0 var(--bs-border-color);
        }
      }
    }
  }
}
